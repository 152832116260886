
$PNSDarkDarkBlue: #15385F;
$PNSDarkGreen: #357B55;
$PNSDarkYellow: #E38E26;
$PNSYellow: #EBC11D;
$PNSBlue: #1C8FE5;
$PNSGreen: #00A178;
$PNSDarkRed: #D52627;
$PNSRed: #E64825;

.green {
        color: green;
}

.yellow {
        color: gold;
}

.red {
        color: red;
}

.blue {
        color: steelblue;
}

.grey {
        color: gray;
}

$mat-PNSGreen: (
        50 : #e0f4ef,
        100 : #b3e3d7,
        200 : #80d0bc,
        300 : #4dbda1,
        400 : #26af8c,
        500 : #00a178,
        600 : #009970,
        700 : #008f65,
        800 : #00855b,
        900 : #007448,
        A100 : #a2ffd6,
        A200 : #6fffbf,
        A400 : #3cffa8,
        A700 : #23ff9d,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$mat-PNSDarkGreen: (
        50 : #e7efeb,
        100 : #c2d7cc,
        200 : #9abdaa,
        300 : #72a388,
        400 : #538f6f,
        500 : #357b55,
        600 : #30734e,
        700 : #286844,
        800 : #225e3b,
        900 : #164b2a,
        A100 : #88ffb2,
        A200 : #55ff91,
        A400 : #22ff70,
        A700 : #08ff60,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$mat-PNSDarkYellow: (
        50 : #fcf1e5,
        100 : #f7ddbe,
        200 : #f1c793,
        300 : #ebb067,
        400 : #e79f47,
        500 : #e38e26,
        600 : #e08622,
        700 : #dc7b1c,
        800 : #d87117,
        900 : #d05f0d,
        A100 : #fffdfc,
        A200 : #ffdec9,
        A400 : #ffbe96,
        A700 : #ffae7d,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$mat-PNSDarkRed: (
        50 : #fae5e5,
        100 : #f2bebe,
        200 : #ea9393,
        300 : #e26768,
        400 : #db4747,
        500 : #d52627,
        600 : #d02223,
        700 : #ca1c1d,
        800 : #c41717,
        900 : #ba0d0e,
        A100 : #ffe7e7,
        A200 : #ffb4b4,
        A400 : #ff8181,
        A700 : #ff6868,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$mat-peablue: (
        50 : #e4f3f9,
        100 : #bce0ef,
        200 : #90cbe5,
        300 : #63b6da,
        400 : #41a7d2,
        500 : #2097ca,
        600 : #1c8fc5,
        700 : #1884bd,
        800 : #137ab7,
        900 : #0b69ab,
        A100 : #d8edff,
        A200 : #a5d6ff,
        A400 : #72bfff,
        A700 : #58b3ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$mat-peagreen: (
        50 : #e7efeb,
        100 : #c2d7cc,
        200 : #9abdaa,
        300 : #72a388,
        400 : #538f6f,
        500 : #357b55,
        600 : #30734e,
        700 : #286844,
        800 : #225e3b,
        900 : #164b2a,
        A100 : #88ffb2,
        A200 : #55ff91,
        A400 : #22ff70,
        A700 : #08ff60,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
