@use '@angular/material' as mat;
@include mat.core();
@import 'colors';

/* You can add global styles to this file, and also import other style files */

$PNSPrimaryBackgroundColor: $PNSDarkDarkBlue;

$prod-primary: mat.define-palette($mat-peablue);
$prod-accent: mat.define-palette($mat-peagreen);
$prod-warn: mat.define-palette($mat-PNSDarkRed);
$prod-theme: mat.define-light-theme($prod-primary, $prod-accent, $prod-warn);

$dev-primary: mat.define-palette($mat-PNSGreen);
$dev-accent: mat.define-palette($mat-PNSDarkGreen);
$dev-warn: mat.define-palette($mat-PNSDarkRed);
$dev-theme: mat.define-light-theme($dev-primary, $dev-accent, $dev-warn);

$typography-configuration: mat.define-typography-config(
        $font-family: '-apple-system, BlinkMacSystemFont, sans-serif',
);

@include mat.all-component-typographies($typography-configuration);

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  overflow: auto;
}

body {
  margin: 0;
}

body#DEV {
  @include mat.all-component-themes($dev-theme);
  background: $PNSDarkGreen;
}

body#PROD {
  @include mat.all-component-themes($prod-theme);
  background: $PNSDarkDarkBlue
}
.mat-icon, mat-icon {
  font-family: 'Material Icons', fantasy !important;
}



mat-icon.spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}


.mat-dialog-container {
  padding: 0 !important;
}
.mat-elevation-z8 {
  width: 100%;
}

table.mat-table {
  width: 100%;
}

.filter {
  width: 400px;
}
.mat-form-field-infix{
  width: 340px;
}

.mat-row:nth-child(even){
  background-color: mat.get-color-from-palette(mat.$grey-palette, 50);
}

button.mat-icon-button {
  color: mat.get-color-from-palette($prod-primary, darker);
}

button.mat-icon-button:hover {
  color: mat.get-color-from-palette($prod-primary);
}

#filter button {
  margin-left: 10px;
  color: mat.get-color-from-palette($prod-primary, darker);
}
// facility-list

#user-list-page {
  padding: 0 1em;
}

h1 button.mat-icon-button {
  vertical-align: middle;
  color: mat.get-color-from-palette($prod-primary, darker);
}

h1.mat-display-1 {
  margin: 16px 0 0 !important;
}


.mat-elevation-z8 {
  width: 100%;
}

table.mat-table {
  width: 100%;
}

.filter {
  width: 400px;
}
.mat-form-field-infix{
  width: 340px;
}

#filter button {
  margin-left: 10px;
  color: mat.get-color-from-palette($prod-primary, darker);
}

.mat-row:nth-child(even){
  background-color: mat.get-color-from-palette(mat.$grey-palette, 50);
}

// org-list

#user-list-page {
  padding: 0 1em;
}
h1 button.mat-icon-button {
  vertical-align: middle;
  color: mat.get-color-from-palette($prod-primary);
}

h1 button.mat-icon-button:hover {
  vertical-align: middle;
  color: mat.get-color-from-palette($prod-primary, lighter);
}

a.teamsLink {
  color: #464EB8 !important;
}

h1.mat-display-1 {
  margin: 16px 0 0 !important;
}

// site-config list

.full-width {
  width: 100%;
}

form {
  min-width: 150px;
  max-width: 800px;
  width: 100%;
}
div.form-actions button {
  margin: 0 1em;
}

span.numItems {
  vertical-align: 6px;
  margin-right: 4px;
}

h1,h2,h3,h4,h5,h6 {margin-bottom: 5px !important;}

.confForm {
  margin: 30px;
}

.mat-body-1.error {
  color: red;
  font-weight: bold;
}

p.mat-body-1.description {
  margin: 0 0 20px 10px !important;
}

div.confForm div {
}



.panelName {
  width: 230px;
}

.panelDesc {
  width: 330px;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: mat.get-color-from-palette($prod-primary, lighter);
}
:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: mat.get-color-from-palette($prod-primary);
}

:host ::ng-deep .mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: mat.get-color-from-palette($prod-warn, lighter);
}
:host ::ng-deep .mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: mat.get-color-from-palette($prod-warn);
}

// User Form
.mat-chip {
  background-color: mat.get-color-from-palette($prod-primary, A100) !important;
  color: mat.get-color-from-palette($prod-primary, A100-contrast !important);
}

div#page {
  margin: 1em;
}

// admint layout
footer {
  height: 4em;
  background-color: map_get(mat.$grey-palette, 50);
  border-top: solid 1px mat.get-color-from-palette(mat.$grey-palette,300);
  text-align: center;
}

footer p {
  margin: 1em;
}

.wf-loading body {
  display: none;
}

.all-wrap {
}

.page-wrap {
  margin: 0 auto;
}

.toolbar-spacer { flex: 1 1 auto; }


.content {
  min-height: 100vh;
}

main {

  > *:not(router-outlet) {
    display: block;
  }
}

#appTitle mat-icon { vertical-align: middle; margin-top: -2px; }

.sidenav-container {
}

.sidenav {
  width: 250px;
  box-shadow: 3px 0 6px rgba(0,0,0,.24);
}

button#userbutton.mat-stroked-button  mat-icon, button#userbutton span { vertical-align: middle; display: inline-block;}
button#userbutton.mat-stroked-button span mat-icon { margin: 0 4px 0 0; }

button#loginbutton.mat-stroked-button  mat-icon, button#loginbutton span { vertical-align: middle; display: inline-block;}
button#loginbutton.mat-stroked-button span mat-icon { margin: 0 4px 0 0; }

#statusIcon {
  color: white;
  position: relative;
  top: 7px;
  right: 4px;
}

// conf form

#page {
  padding: 0 1em;
}

h1.mat-display-1 {
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 5px;
}

h1 button.mat-icon-button {
  vertical-align: middle;
  color: mat.get-color-from-palette($prod-primary, darker);
}

.sticky {
  backdrop-filter: drop-shadow(4px 4px 10px #111111);
  position: fixed;
  bottom: 10px;
  z-index: 1000;
}

h1 button.mat-mini-fab {
  position: relative;
  top: -5px;
  margin-right: 10px;
}

h1,h2,h3,h4,h5,h6 {margin-bottom: 5px !important;}

.confForm {
  margin: 30px;
}

p.mat-body-1.description {
  margin: 0 0 20px 10px !important;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

div.confForm div {
  margin-left: 10px;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: mat.get-color-from-palette($prod-primary, lighter);
}
:host ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: mat.get-color-from-palette($prod-primary);
}

:host ::ng-deep .mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: mat.get-color-from-palette($prod-warn, lighter);
}
:host ::ng-deep .mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: mat.get-color-from-palette($prod-warn);
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

//One time use form

form {
  min-width: 150px;
  max-width: 800px;
  width: 100%;
}
.full-width {
  width: 100%;
}

div.panelName {
  width: 200px;
}

#user-list-page {
  padding: 0 1em;
}

h1 button.mat-mini-fab {
  vertical-align: middle;
  background-color: mat.get-color-from-palette($prod-primary, darker);
  color: white;
}

h1.mat-display-1 {
  margin: 16px 0 0 !important;
}

.mat-green {
  color: $PNSDarkGreen !important;
}
